<template>
<modal :modalId="`modaComfirmBB`" :modalTitle="'Deseja Finalizar este Pedido?'" :load="false" :typeLg="`modal_ws`" @closeModal="closeModal">
  <div class="d-flex align-content-center justify-content-around mt-5">
    <button class="btnFinish danger" @click="closeModal">
      Voltar
    </button>
    <button class="btnFinish green" @click="$emit('finishService')">
      Finalizar
    </button>
  </div>
</modal>
</template>

<script>
import modal from '@/components/modal/modalDefault'
import tabs from './tabs.vue'
export default {
  props: ['list'],
  components: {
    modal,
    tabs
  },
  methods: {
    closeModal () {
      $('#modaComfirmBB').modal('hide')
    }
  }
}
</script>

<style scoped>
.btnFinish {
  background-color: transparent;
  border-radius: 6px;
  border: none;
  color: #fff;
  height: 40px;
  padding: 0 20px;
  outline: none;
  transition: all 0.2s linear;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.green {
    background: #25d366;
}

.danger {
  /* background-color: #d32525; */
  border: 1px solid #d32525;
  color: #d32525;
}
.danger:hover {
  background-color: #ad1813;
  color: #fff;
}
.green:hover {
  background: #13ad4c;
}
</style>
