<template>
  <div>
    <div class="col-md-12 mt-4 containerHeader">
      <div class="row">
        <div class="col-4">
          <h2>Serviço</h2>
        </div>
        <div class="col-3 text-center">
          <h2>Quantidade</h2>
        </div>
        <div class="col-3 text-center">
          <h2>Dados do Cliente</h2>
        </div>
        <!-- <div class="col-3 text-center">
          <h2>Valor Unitário (R$)</h2>
        </div>
        <div class="col-2">
          <h2>Subtotal (R$)</h2>
        </div> -->
      </div>
    </div>
    <!-- {{ itemRequest.ordersServices }} -->
    <div class="containerServices">
      <containerServico
        v-for="(item, index) in itemRequest.ordersServices"
        :key="index"
        :item="item"
        :quantityItem="itemRequest.ordersServices.quantity"
      />
      <!-- {{ itemRequest }} -->
    </div>
    <div
      class="col-12 containerPay d-flex align-items-center justify-content-between"
    >

      <valueAll :price="itemRequest.totalValue" />
    </div>
    <modalBB :list="itemRequest" />
  </div>
</template>

<script>
import containerServico from './container_servico/containerBB.vue'
import valueAll from './ValueAll'
import modalBB from './container_servico/modalBB.vue'
export default {
  props: {
    itemRequest: {
      type: Object,
      default: {}
    }
  },
  components: {
    containerServico,
    valueAll,
    modalBB
  },
  methods: {

  }
}
</script>

<style scoped>
* {
  font-style: normal;
  font-weight: 500;
}
.containerHeader h2 {
  font-size: 1rem;
  font-weight: 500;
  color: #ffffff;
}
.containerPay {
  margin-top: 20px;
  background: #206aad;
  border-radius: 8px;
  height: 65px;
}
.btnDefault {
  background: #f38235;
  border-radius: 6px;
  border: none;
  color: #fff;
  height: 40px;
  width: 162px;
  padding: 0 20px;
  border: none;
  transition: all 0.2s linear;
  outline: none;
}
.btnDefault:hover {
  background-color: #e0a638;
}

.containerPay strong {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
}

.containerPay h3 {
  color: #98c4e6;
  font-weight: 700;
  font-size: 20px;
  margin: 0;
}
</style>
