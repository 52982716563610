<template>
  <div>
    <div>
			<h3>
				Valor Total: <strong>{{ price | currency }}</strong>
			</h3>
		</div>
  </div>
</template>

<script>
export default {
  props: ['price']

}
</script>

<style scoped>
h3 {
  color: #98C4E6;
  font-weight: 700;
  font-size: 20px;
  margin: 0;
}

strong {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #FFFFFF;
}

</style>